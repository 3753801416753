<template>
  <div class="special-ico">
    <Header titleurl="special" />
    <div class="content">
      <div class="selector">
        <div
          class="J_selectorLine s-category"
          v-for="(item, i) in searchcategorylist"
          :key="i"
        >
          <div class="sl-wrap">
            <div class="sl-key">
              <strong>{{ item.screenName }}:</strong>
            </div>
            <div class="sl-value">
              <div class="sl-v-list">
                <ul class="J_valueList">
                  <li v-for="(jtem, i) in item.subScreenList" :key="i">
                    <a
                      :title="jtem.screenName"
                      class="cate"
                      :class="cateid == jtem.screenId ? 'selected' : ''"
                      href="javascript:;"
                      @click="ongetsubcategorylist(jtem, 1)"
                      ><i></i>{{ jtem.screenName }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="sl-btns">
                <a
                  class="btn btn-primary J_btnsConfirm disabled"
                  href="javascript:;"
                  >确定</a
                >
                <a class="btn btn-default J_btnsCancel" href="javascript:;"
                  >取消</a
                >
              </div>
            </div>
            <div class="sl-ext">
              <a
                class="sl-e-more J_extMore"
                href="javascript:;"
                style="visibility: hidden;"
                >更多<i></i
              ></a>
              <a
                class="sl-e-multiple J_extMultiple"
                href="javascript:;"
                style="visibility: hidden;"
                >多选<i></i
              ></a>
            </div>
          </div>
        </div>
        <div
          class="J_selectorLine s-category"
          v-for="(item, i) in searchsubcategorylist"
          :key="i"
        >
          <div class="sl-wrap">
            <div class="sl-key">
              <strong>{{ item.screenName }}:</strong>
            </div>
            <div class="sl-value">
              <div class="sl-v-list">
                <ul class="J_valueList">
                  <li v-for="(jtem, i) in item.subScreenList" :key="i">
                    <a
                      :title="jtem.screenName"
                      href="javascript:;"
                      class="cate"
                      :class="subcateid == jtem.screenId ? 'selected' : ''"
                      @click="ongetsubcategorylist(jtem, 2)"
                      ><i></i>{{ jtem.screenName }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="sl-btns">
                <a
                  class="btn btn-primary J_btnsConfirm disabled"
                  href="javascript:;"
                  >确定</a
                >
                <a class="btn btn-default J_btnsCancel" href="javascript:;"
                  >取消</a
                >
              </div>
            </div>
            <div class="sl-ext">
              <a
                class="sl-e-more J_extMore"
                href="javascript:;"
                style="visibility: hidden;"
                >更多<i></i
              ></a>
              <a
                class="sl-e-multiple J_extMultiple"
                href="javascript:;"
                style="visibility: hidden;"
                >多选<i></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="contents">
        <div class="contensBoxs">
          <div class="contentsLeft">
            <div
              class="advertisementShop"
              v-for="(item, i) in productspeclist"
              :key="i"
            >
              <router-link
                class=""
                target="_blank"
                :to="'/product/detailclothing?id=' + item.id"
              >
                <div class="advertisementImg">
                  <img class="adImg" :src="item.imagePath" alt="" />
                  <div class="advertisementDescribe">{{ item.customName }}</div>
                </div>
                <div class="pricebox">
                  <b class="price"
                    ><span class="rmb">¥</span>{{ item.minDiscountPrice }}</b
                  >
                  <em class="delprice">¥{{ item.minPrice }}</em>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="contentsRight">
          <div class="FilterList">
            <div class="FilterListLeft">
              <div
                class="filterBox1"
                v-for="(item, index) in nameList"
                :key="index"
                @click="getIndexs(index)"
                :class="nameIndex == index ? 'active' : ''"
              >
                <span>{{ item.name }}</span>
                <span class="filterBoxIcon"
                  ><i class="el-icon-caret-bottom"></i
                ></span>
              </div>
              <div
                class="filterBox5"
                @click="getfilterBox5Click"
                :class="filterBox5 ? 'active' : ''"
              >
                <div class="filterBox5Title">价格</div>
                <div class="filterBox5Div1">
                  <div
                    class="filterBox5Div2"
                    @click="filterBox5Div2"
                    :class="filterBox5Div1 ? '' : 'active2'"
                  >
                    <i class="el-icon-caret-top"></i>
                  </div>
                  <div
                    class="filterBox5Div3"
                    @click="filterBox5Div3"
                    :class="filterBox5Div1 ? 'active2' : ''"
                  >
                    <i class="el-icon-caret-bottom"></i>
                  </div>
                </div>
              </div>
              <!-- 输入框筛选 -->
              <div
                class="filterForm"
                @mouseover="formMouseover"
                @mouseout="formMouseout"
              >
                <el-form>
                  <div class="FilterListInputBox">
                    <el-form-item>
                      <el-input
                        class="input1"
                        v-model="input1"
                        placeholder="￥"
                      ></el-input>
                    </el-form-item>
                    <span class="lineIcon">-</span>
                    <el-form-item>
                      <el-input
                        class="input1"
                        v-model="input2"
                        placeholder="￥"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="FilterListInputBtn" v-show="filterForm">
                    <el-button size="mini">清空</el-button>
                    <el-button size="mini">确认</el-button>
                  </div>
                </el-form>
              </div>
            </div>
            <div class="FilterListRight">
              <div class="filterRDescribe">
                <span>共</span>
                <span class="filtercolor">30+</span>
                <span>件商品</span>
              </div>
              <div class="filterRPrice">
                <span class="filtercolor1">1</span>
                <span>/1</span>
              </div>
              <div class="filterRBtn">
                <div class="filterBox6">
                  <span class="filterBoxIcon"
                    ><i class="el-icon-arrow-left"></i
                  ></span>
                </div>
                <div class="filterBox7">
                  <span class="filterBoxIcon"
                    ><i class="el-icon-arrow-right"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div class="commodity">
            <div
              class="container_row_spec custom"
              v-for="(item, i) in productlist"
              :key="i"
            >
              <router-link
                :to="'/product/detailclothing?id=' + item.id"
                target="_blank"
              >
                <img
                  :src="item.imagePath"
                  onerror="javascript:this.src='//5004A8DA240BFA8F.newoa.jinyingu.cn/upload/product/2020/09/09/090923454851103466.jpg';"
                  width="180"
                  height="180"
                />
                <div class="container_row_map">{{ item.customName }}</div>
                <div class="container_row_place pricebox">
                  <b class="price"
                    ><span class="rmb">¥</span>{{ item.minDiscountPrice }}</b
                  >
                  <em
                    class="delprice"
                    v-if="item.minDiscountPrice != item.minPrice"
                    >¥{{ item.minPrice }}</em
                  >
                </div>
              </router-link>
            </div>
          </div>
          <div class="page">
            <el-pagination
              @size-change="handleSizeChange"
              background
              @current-change="handleCurrentChange"
              :current-page="pageIndex"
              :page-sizes="[12, 24, 36]"
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import {
  productgetcustomlist,
  productgetindexcustomlist,
  productgetcustomscreen
} from '@/api/product';
export default {
  name: 'productlist',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      currcateid: 259,
      ScreenDataList: null,
      cateid: 0,
      subcateid: 0,
      input1: '',
      input2: '',
      // 筛选标题数据
      nameList: [
        { name: '综合' },
        { name: '销量' },
        { name: '评论数' },
        { name: '新品' }
      ],
      // 数字类型的变量
      nameIndex: 0,
      filterBox5: false,
      filterBox5Div1: false,
      // 经过与离开筛选框时，判断显示与隐藏
      filterForm: false,
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
      productlist: [],
      productspeclist: [],
      searchcategorylist: [],
      searchsubcategorylist: []
    };
  },
  mounted() {
    this.onproductgetcustomlist();
    this.onproductgetindexcustomlist();
    this.onproductgetcustomscreen();
  },
  methods: {
    ongetsubcategorylist(item, lev) {
      if (lev == 1) {
        if (this.ScreenDataList == item.screenId) {
          this.ScreenDataList = null;
          this.cateid = 0;
        } else {
          this.searchsubcategorylist = [];
          this.cateid = item.screenId;
          this.searchsubcategorylist = item.subScreenList;
          //this.currcateid=this.cateid;
          this.ScreenDataList = this.cateid;
        }
      } else {
        if (this.ScreenDataList == item.screenId) {
          this.ScreenDataList = null;
          this.subcateid = 0;
        } else {
          this.subcateid = item.screenId;
          //this.currcateid=this.subcateid;
          this.ScreenDataList = this.subcateid;
        }
      }
      this.onproductgetcustomlist();
    },
    onproductgetindexcustomlist() {
      var that = this;
      productgetindexcustomlist({ count: 5, cateid: 259 }).then(res => {
        if (res.data.code === 1) {
          that.productspeclist = res.data.result;
        }
      });
    },
    onproductgetcustomscreen() {
      var that = this;
      productgetcustomscreen({
        CateId: 259
      }).then(res => {
        if (res.data.code == 1) {
          that.searchcategorylist = res.data.result;
        }
      });
    },
    onproductgetcustomlist() {
      var that = this;
      productgetcustomlist({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        CateId: 259,
        ScreenDataList: this.ScreenDataList
      }).then(res => {
        if (res.data.code == 1) {
          that.productlist = res.data.result.list;
          that.totalCount = res.data.result.totalCount;
        }
      });
    },
    handleCurrentChange(item) {
      console.log(item);
      this.pageIndex = item;

      this.onproductgetcustomlist();
    },
    handleSizeChange(item) {
      this.pageSize = item;
      this.onproductgetcustomlist();
    },
    getIndexs(index) {
      this.nameIndex = index;
      this.filterBox5 = false;
    },
    getfilterBox5Click() {
      this.filterBox5 = true;
      this.nameIndex = this.nameIndex + 20;
      this.filterBox5Div1 = true;
    },
    filterBox5Div2(e) {
      e.stopPropagation();
      this.filterBox5Div1 = true;
    },
    filterBox5Div3(e) {
      this.filterBox5Div1 = false;
      e.stopPropagation();
    },
    formMouseover() {
      this.filterForm = true;
    },
    formMouseout() {
      this.filterForm = false;
    }
  }
};
</script>

<style scoped>
a {
  color: #606266;
}
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.special-ico {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.content {
  width: 100%;
  margin-top: 20px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 700px;
}
.contents {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.contentsLeft {
  width: 180px;
  border: 1px solid #dddddd;
}
.special-ico .clearfix {
  clear: both;
}
.advertisementShop {
  width: 160px;
  height: 180px;
  margin: 10px auto;
  overflow: hidden;
}
.advertisementImg {
  width: 160px;
  height: 160px;
  position: relative;
}
.adImg {
  width: 100%;
  height: 100%;
}
.advertisementPrice {
  font-size: 14px;
  color: #e4393c;
  margin-bottom: 5px;
}
.advertisementDescribe {
  font-size: 12px;
  color: #666;
  white-space: pre-wrap;
  padding: 5px;
  position: absolute;
  bottom: 0;
  background-color: #1c1c1c;
  opacity: 0.76;
  width: 160px;
  color: #fff;
}
.contentsRight {
  width: 1000px;
  margin-left: 20px;
}
.FilterList {
  display: flex;
  justify-content: space-between;
  width: 1000px;
  height: 40px;
  border-top: 1px solid #e7e3e7;
  border-bottom: 1px solid #e7e3e7;
  background-color: #f1f1f1;
}
.FilterListLeft {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}
.filterBox1 {
  width: 60px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  margin-top: 7px;
  color: #999;
}
.filterBox5 {
  width: 60px;
  height: 25px;
  margin-top: 7px;
  color: #999;
}
.filterBox1 {
  border-top: 1px solid #999;
  border-left: 1px solid #999;
  border-bottom: 1px solid #999;
}
.filterBox5 {
  display: flex;
  flex-direction: row;
  border: 1px solid #999;
  margin-right: 5px;
}
.filterBoxIcon {
  margin-left: 3px;
}
.input1 {
  width: 62px;
  height: 28px;
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 5px;
}
.input1 >>> .el-input__inner {
  /* border-radius: 4px; */
  border: 1px solid #999;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  /* height: 40px; */
  /* line-height: 40px; */
  height: 26px;
  line-height: 26px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.lineIcon {
  display: block;
  margin-top: 20px;
}
.FilterListRight {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}
.filterRDescribe {
  height: 25px;
  text-align: center;
  line-height: 25px;
  margin-top: 7px;
  color: #999;
  margin-right: 15px;
}
.filtercolor {
  color: #666;
}
.filterRPrice {
  height: 25px;
  text-align: center;
  line-height: 25px;
  margin-top: 7px;
  color: #999;
  margin-right: 15px;
}
.filtercolor1 {
  color: #e4393c;
}
.filterRBtn {
  display: flex;
  flex-direction: row;
}
.filterBox6,
.filterBox7 {
  width: 50px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  margin-top: 7px;
  color: #cccccc;
}
.filterBox6 {
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}
.filterBox7 {
  border: 1px solid #cccccc;
  margin-right: 10px;
}
.commodity {
  display: flex;
  flex-wrap: wrap;
}
.container_row_spec {
  width: 220px;
  height: 303px;
  background-color: #fff;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.2s linear;
  margin-top: 20px;
  margin-left: 10px;
}
.container_row_spec:hover {
  transform: translate3d(0, -2px, 0);
}
.container_row_img {
  cursor: pointer;
}
.container_row_spec:nth-child(5),
.container_row_spec:nth-child(10) {
  margin-right: 0px !important;
}
.container_row_name {
  color: #666;
  font-size: 14px;
  background-color: #fff;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0 10px;
  margin-top: 10px;
}
.container_row_name img {
  position: relative;
  top: 5px;
  margin-left: 9px;
}
.container_row_name1 {
  color: #999;
  font-size: 14px;
  /* width: 100%; */
  background-color: #fff;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0 10px;
  margin-top: 10px;
}
.container_row_name1 img {
  position: relative;
  top: 5px;
  margin-left: 9px;
}
.container_row_price {
  background-color: #fff;
  font-size: 20px;
  padding-left: 10px;
  color: #e4393c;
  margin-top: 5px;
}
.container_row_price .rmb {
  font-size: 20px;
  color: #e4393c;
}
.container_row_spec {
  height: auto;
}
.container_row_price .mode {
  font-size: 12px;
  color: #666;
}
.active {
  background-color: #e4393c;
  color: #fff;
  border-top: 1px solid #e4393c;
  border-left: 1px solid #e4393c;
  border-bottom: 1px solid #e4393c;
}
.filterBox5Div1 {
  display: flex;
  flex-direction: column;
}
.filterBox5Title {
  margin-left: 10px;
  margin-top: 3px;
  margin-right: 2px;
}
.filterBox5Div2 {
  margin-top: 1px;
}
.filterBox5Div3 {
  margin-top: -3px;
}
.active2 {
  color: #999;
}
.FilterListInputBox {
  display: flex;
  flex-direction: row;
  margin-top: -6px;
}
.filterForm {
  display: flex;
  flex-direction: column;
  height: 80px;
  z-index: 999;
}
.filterForm:hover {
  border: 1px solid #999;
  box-shadow: 1px 0px 0px #d0d0d0;
  background: #fff;
}
.FilterListInputBtn {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
}
.container_row_spec {
  width: 220px;
  height: 263px;
  background-color: #fff;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.2s linear;
}

.container_row_spec img.container_row_img {
  width: 100%;
  height: 220px;
}

.container_row_spec:hover {
  transform: translate3d(0, -2px, 0);
}
.container_row_img {
  cursor: pointer;
}
/* .container_row_spec:nth-child(5),
.container_row_spec:nth-child(10) {
  margin-right: 0px !important;
} */
.container_row_name {
  color: #666;
  height: 35px;
  font-size: 12px;
  width: 100%; /* margin-top: -4px; */
  background-color: #fff;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 40px;
}
.container_row_name img {
  position: relative;
  top: 5px;
  margin-left: 9px;
}
.container_row_price {
  background-color: #fff;
  font-size: 20px;
  padding: 0 10px;
  color: #b31e22;
}
.container_row_price .rmb {
  font-size: 12px;
  color: #666;
}
.container_row_spec.custom {
  height: 180px;
  width: 180px;
  position: relative;
  margin-bottom: 20px;
}
.container_row_price .mode {
  font-size: 12px;
  color: #666;
}

.container_row_map {
  width: 100%;
  margin-top: 0;
  height: 28px;
  background-color: #1c1c1c;
  opacity: 0.76;
  bottom: 0px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 28px;
  padding-left: 9px;
  box-sizing: border-box;
  color: #fff;
  position: absolute;
}

.pricebox .price {
  color: #ff0000cc;
  font-size: 17px;
  font-weight: bold;
}

.pricebox .price .rmb {
  font-size: 12px;
}

.pricebox .delprice {
  font-size: 12px;
  color: #999;
  text-decoration: line-through;
  margin-left: 16px;
  font-weight: normal;
}

.clearfix {
  clear: both;
}
.page {
  margin-top: 30px;
  text-align: center;
}

.selector {
  border-top: 1px solid #ddd;
  background: #fff;
  margin-bottom: 10px;
}
.selector .sl-wrap {
  /* position: relative; */
  line-height: 34px;
  border-bottom: 1px solid #ddd;
  background: #f3f3f3;
}
.selector .sl-key {
  float: left;
  width: 100px;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  line-height:40px; 
}
.selector .sl-value {
  margin-left: 110px;
  padding-right: 130px;
  padding-left: 10px;
  overflow: hidden;
  zoom: 1;
  background: #fff;
}
.selector .sl-v-list {
  overflow: hidden;
  zoom: 1;
  padding-top: 5px;
  padding-bottom: 5px;
}
.selector .sl-v-list ul {
  float: left;
  overflow: hidden;
  zoom: 1;
  position: relative;
  /* height: 30px; */
}
.selector .sl-v-list li {
  float: left;
  _display: inline;
  margin-right: 50px;
  height: 30px;
  line-height: 30px;
}
.selector .s-category .sl-v-list li {
  width: 130px;
  margin-right: 5px;
}
.selector .sl-v-list li a {
  float: left;
  white-space: nowrap;
  zoom: 1;
  color: #005aa0;
}
.selector .sl-btns {
  display: none;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.selector .sl-ext {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 110px;
  height: auto;
  line-height: 22px;
  overflow: hidden;
  zoom: 1;
}
.selector a.cate:hover {
  color: #f6671a;
}
.selector a.cate.selected {
  color: #f6671a;
}
</style>
